/* eslint-disable */
// @ts-nocheck
// This file was generated by lezer-generator. You probably shouldn't edit it.
export const RawQuoted1Open = 1,
	RawQuoted2Open = 2,
	RawHtmlOpen = 3,
	RawQuoted1Content = 4,
	RawQuoted2Content = 5,
	RawHtmlContent = 6,
	RawQuoted1Close = 7,
	RawQuoted2Close = 8,
	RawHtmlClose = 9,
	Program = 10,
	Not = 11,
	Regex = 12,
	SimpleString = 13,
	Quoted1 = 14,
	Quoted1Open = 15,
	Squared_1 = 16,
	SquareOpen_1 = 17,
	Dash = 18,
	Char = 19,
	RangeOpen = 20,
	RangeClose = 21,
	SquareClose = 22,
	Wildcard_1 = 23,
	Wildcard1_1 = 24,
	Content_1 = 25,
	Quoted1Close = 26,
	Quoted2 = 27,
	Quoted2Open = 28,
	Squared_2 = 29,
	SquareOpen_2 = 30,
	Wildcard_2 = 31,
	Wildcard1_2 = 32,
	Content_2 = 33,
	Quoted2Close = 34,
	Number = 35,
	Html = 36,
	HtmlOpen = 37,
	Squared_3 = 38,
	SquareOpen_3 = 39,
	Wildcard_3 = 40,
	Wildcard1_3 = 41,
	Content_3 = 42,
	HtmlClose = 43,
	RawQuoted = 44,
	RawHtml = 45,
	Group = 46,
	Label = 47,
	tag = 48,
	noteTag = 49,
	cardTag = 50,
	template = 51,
	setting = 52,
	Is = 53,
	Or = 54,
	templateId = 55,
	cardId = 56,
	noteId = 57,
	settingId = 58,
	state = 59,
	StateEnum = 60,
	kind = 61,
	KindEnum = 62,
	field = 63,
	FieldName = 64,
	FieldValueEnum = 65,
	due = 66,
	DueEnum = 67,
	_true = 68,
	_false = 69,
	Comparison = 70,
	Date = 71,
	reps = 72,
	lapses = 73,
	tagCount = 74,
	cardTagCount = 75,
	noteTagCount = 76,
	created = 77,
	noteCreated = 78,
	cardCreated = 79,
	edited = 80,
	noteEdited = 81,
	cardEdited = 82,
	reviewed = 83,
	firstReviewed = 84,
	RatingEnum = 85
